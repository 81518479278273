<template>
  <div>
    <div>
      <div class="project-search">
        <div class="flex search-text align-center">
          <div>
            <span>类型：</span>
            <el-select v-model="pageInfo.utensilType" placeholder="请选择资产类型" size="large">
              <el-option label="全部" value="" />
              <el-option label="耗材" value="0" /><el-option label="器具" value="1" />
            </el-select>
          </div>
          <div class="marginLeft15">
            <span>资产名称：</span>
            <input v-model="pageInfo.utensilName" placeholder="器材/耗材名称" class="searchInput" />
          </div>
          <div class="marginLeft15">
            <span>实验室：</span>
            <el-select v-model="pageInfo.laboratoryId" filterable placeholder="请选择一个实验室" size="large">
              <el-option v-for="item in roomList" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </div>
          <button class="searchBtn" @click="search">搜索</button>
          <button class="resetBtn" @click="reset(null)">重置</button>
        </div>
      </div>
      <div class="project-table" style="margin-top: 20px">
        <div class="flex align-center justify-between paddingBTM20">
          <div class="fontSize20gay">资产使用记录</div>
        </div>
        <el-table :data="tableData" border row-key="id"
                  :header-cell-style="{'text-align':'center','color':'#999999','padding':'16px 0','background-color':'#EBEBEB'}"
                  :cell-style="{'text-align':'center'}">
          <el-table-column prop="name" label="类型">
            <template #default="scope">
              <span>{{scope.row?(scope.row.utensil?(scope.row.utensil.type==1?'器具':'耗材'):''):''}}</span>
            </template>
          </el-table-column>
          <el-table-column label="资产名称">
            <template #default="scope">
              <span>{{scope.row?(scope.row.utensil?scope.row.utensil.name:''):''}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="number" label="使用数量" width="90" />
          <el-table-column prop="name" label="所属实验室">
            <template #default="scope">
              <span>{{scope.row?(scope.row.reservation?(scope.row.reservation.laboratory?scope.row.reservation.laboratory.name:''):''):''}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="预约人">
            <template #default="scope">
              <span>{{scope.row?(scope.row.reservation?(scope.row.reservation.user?scope.row.reservation.user.name:''):''):''}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="角色">
            <template #default="scope">
              <span>{{scope.row?(scope.row.reservation?(scope.row.reservation.type==1?'教师':'学生'):''):''}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="returnDate" label="归还日期" />
          <el-table-column prop="returnNumber" width="90" label="归还数量" />
          <el-table-column label="使用日期">
            <template #default="scope">
              <span>{{scope.row?(scope.row.reservation?scope.row.reservation.reservationTime:''):''}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="notes" label="备注" />
          <el-table-column fixed="right" label="操作" width="150">
            <template #default="scope">
              <el-button link type="primary"
                         :style="{color: canHsiangHsien(scope.row) ? '#3769B9' : '#999999'}"
                         @click="hsiangHsien(scope.row)"
                         :disabled="!canHsiangHsien(scope.row)">归还</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="flex justify-center paddingTD20">
          <!--   分页     -->
          <el-pagination
              :currentPage="pageInfo.page"
              :page-size="pageInfo.limit"
              :page-sizes="[10, 20, 50, 100]"
              :small="true"
              :disabled="false"
              :background="true"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageInfo.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <!-- 操作窗口   -->
  </div>
</template>

<script>
import {getAssetHistory, getRoomList, hsiangHsienEquipment} from "@/api/api";
export default {
  name: "AssetList",
  data(){
    return{
      token:localStorage.getItem('token'),
      tableData:[],
      dataInfo:[],
      Info:null,
      dialogFormVisible:false,
      pageInfo:{
        limit:10,
        name:'',
        utensilName:'',
        laboratoryId:'',
        utensilType:'',
        page:1,
        total:0
      },
      roomList:[],
      exportIds:''
    }
  },
  mounted() {
    this.getList();
    this.getList1();
  },
  methods:{
    /**
     * 是否可归还
     * @param row
     */
    canHsiangHsien({ replay, reservation }){
      // 器具 并且未归还
      return !replay && String(reservation.type) === '1'
    },
    /**
     * 归还
     */
    hsiangHsien(row){
      this.$prompt('输入归还数量', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^(?:[1-9][0-9]*|[1-9])$/,
        inputErrorMessage: '输入不正确'
      }).then(({ value }) => {
        hsiangHsienEquipment(row.id, value).then(res => {
          if(res.success){
            this.$root.ElMessage({type:'success',message:res.message});
          }else{
            this.$root.ElMessage.error(res.message);
          }
        })
      })
    },
    reset(){
      this.pageInfo.utensilName='';
      this.pageInfo.laboratoryId='';
      this.pageInfo.utensilType='';
    },
    search(){
      this.pageInfo.limit=10;
      this.pageInfo.page=1;
      this.getList();
    },
    getList(){
      getAssetHistory(this.pageInfo).then(res=>{
        this.pageInfo.total=parseInt(res.data.total);
        this.tableData=res.data.records;
      })
    },
    getList1(){
      getRoomList().then((res)=>{
        this.roomList=res.data;
      })
    },
    handleSizeChange(e){//修改每页显示条数
      this.pageInfo.limit=e;
      this.pageInfo.page=1;
      this.getList();
    },
    handleCurrentChange(e){
      this.pageInfo.page=e;
      this.getList();
    },
  }
}
</script>

<style lang="scss" scoped>
.data-title{font-size: 15px;color: #000000}
.data-content{
  font-size: 13px;display: flex;justify-content: space-between;margin-top: 15px;
}
</style>